import React from 'react';
import { useNavigate } from 'react-router-dom';

import { calenderIcon } from '../../../assets';
import { Spinner } from '../../../components/Elements';
import {
  formatDate,
  convertToAbbreviation,
  convertTime,
} from '../../../utils/converter';
import { useGetPreviousLiveSessions } from '../api';
import { LiveSessionProps } from '../types';

import { UpcomingSessionPrice } from './UpcomingSessionPrice';

const PreviousSession: React.FC<{
  data: LiveSessionProps;
  hasBottomMargin: boolean;
}> = ({ data, hasBottomMargin }) => {
  const isAdminDashboard = location.pathname.includes('admin');
  const navigate = useNavigate();
  return (
    <div
      className={`flex ${isAdminDashboard ? 'flex-1' : ''} flex-col sm:flex-row sm:h-[150px] border-[#D9D9D9] border-[1px] ${hasBottomMargin ? 'mb-4' : ''}`}
    >
      <div className='flex sm:flex-[0.3]'>
        <img
          src={data.image?.presigned_url}
          alt={data.title}
          className='w-full h-full object-cover'
        />
      </div>

      <div className='flex flex-col sm:flex-[0.7] ml-4 m-4'>
        <h3 className='text-xl font-bold text-secondary mb-4'>{data.title}</h3>
        {/* <p className='text-slate-500'>{data.about}</p> */}
        {isAdminDashboard && (
          <>
            <div className='flex justify-between text-sm'>
              <div>
                <span>Duration: </span>
                <span className='font-bold'>
                  {parseInt(data.duration_weeks)} Weeks
                </span>
              </div>

              <button
                onClick={() => {
                  navigate(`/admin/live-sessions/view/${data.id}`);
                }}
                className='w-[180px] py-2 rounded border-[1px] border-secondary hover:border-primary hover:text-primary font-semibold'
              >
                View Details
              </button>
            </div>

            <div>
              <span className='text-xs'>{data.no_of_students} students</span>
            </div>
          </>
        )}

        {!isAdminDashboard && (
          <div className=' text-secondary font-semibold'>
            <div className='flex items-center mb-2 text-sm'>
              <img
                src={calenderIcon}
                alt='calender icon'
                className='w-4 h-4 mr-1 hidden lg:block'
              />
              {formatDate(new Date(data.start_date))} -{' '}
              {parseInt(data.duration_weeks)} Weeks
            </div>
            <div className='text-xs mb-2'>
              {data.class_days
                .map((day) => convertToAbbreviation(day))
                .join(' & ')}{' '}
              {convertTime(data.start_time)?.cstTime} (CST) |{' '}
              {convertTime(data.start_time)?.watTime} (WAT)
            </div>
            {/* <div className='text-xs mb-2'>
              Duration: {parseInt(data.duration_hours)} Hours
            </div> */}
            <div className='text-sm mb-2'>
              <span>Price:</span> <UpcomingSessionPrice data={data} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const PreviousSessions = () => {
  const { data, isLoading } = useGetPreviousLiveSessions();
  const isAdminDashboard = location.pathname.includes('admin');

  return (
    <div className='min-h-[30vh] py-4 sm:py-8 '>
      <h1 className='text-xl text-secondary font-bold'>
        Previous Live Sessions
      </h1>
      {isLoading && (
        <div className='min-h-[30vh] flex flex-col  items-center justify-center'>
          <Spinner size='md' />
        </div>
      )}
      {data && (
        <div
          className={`my-4 ${isAdminDashboard ? '' : 'md:grid grid-cols-1 sm:grid-cols-2 gap-2'}`}
        >
          {data.length ? (
            data.map((prevSession: LiveSessionProps, index: number) => {
              return (
                <PreviousSession
                  data={prevSession}
                  key={data.id}
                  hasBottomMargin={index < data.length - 1}
                />
              );
            })
          ) : (
            <h3 className='text-slate-500 font-semibold'>No data to display</h3>
          )}
        </div>
      )}
    </div>
  );
};
